.banner {
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.banner .slider {
    position: absolute;
    width: 200px;
    height: 250px;
    top: 10%;
    left: calc(50% - 100px);
    transform-style: preserve-3d;
    transform: perspective(600px);
    animation: autoRun 18s linear infinite;
}

@keyframes autoRun {
    from {
        transform: perspective(600px) rotateY(0deg);
    }

    to {
        transform: perspective(600px) rotateY(360deg);
    }
}

.banner .slider .item {
    position: absolute;
    inset: 0 0 0 0;
    transform:
        rotateY(calc((var(--position) - 1) * (360 / var(--quantity)) * 1deg)) translateZ(550px);
}

.banner .slider .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}